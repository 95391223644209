
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import { imgUpload, updateProject } from '@/api/project/project';
import Message from 'element-plus/es/el-message';
import { ElMessage } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';

export default defineComponent({
  name: 'ProjectItem',
  props: {
    dataObj: {
      type: Object,
      default: () => ({
        thumbnail: '',
        name: '微领智能建造',
        owner: ['张三'],
        description: 'APP端项目描述',
        level: '平台级',
        status: 'using',
        accessType: '永久',
      }),
    },
    deleteOrNot: {
      type: Boolean,
      default: () => true,
    },
    updateOrNot: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props, ctx) {
    const selectPic = ref(null as any);
    const src = ref('');
    const changeSelectPic = (res: any) => {
      const { files } = res.target;
      if (files[0].size > 3 * 1024 * 1024) {
        return Message.warning('上传图片不得大于3Mb');
      }
      if (!(files[0].type.includes('jpeg') || files[0].type.includes('png'))) {
        return Message.warning('图片格式必须为png/jpeg');
      }
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (ev: any) => {
        src.value = ev.target.result;
      };
      const formData = new FormData();
      formData.append('file', files[0]);
      imgUpload(formData)
        .then((res) => updateProject(props.dataObj.id, { thumbnail: res.data.fileKey }))
        .then(() => ctx.emit('reload-projects'));
    };
    const changePic = () => {
      const hasAuth = getShowBool('update');
      if (props.updateOrNot && hasAuth) {
        selectPic.value.click();
      } else {
        ElMessage.warning('暂无更新权限');
      }
    };

    const levelArr = ['', '通用级', '行业级', '租户级'];
    const deleteProject = () => {
      ctx.emit('deleteProject', props.dataObj.id);
    };
    const { proxy } = getCurrentInstance() as any;

    const jump2detail = () => {
      const isDelete = props.dataObj.delState > 0;
      if (isDelete) {
        return;
      }
      const hasAuth = getShowBool('selectDetail');
      if (hasAuth) {
        proxy.$router.push({
          path: `/project-management/project-detail/${props.dataObj.id}`,
        });
      } else {
        ElMessage.warning('暂无查看项目详情权限');
      }
    };
    const provpers = computed(() => {
      const str = props.dataObj?.remark;
      const length = props.dataObj?.remark?.length || 1;
      const n = 20;
      const arr = [];
      for (let i = 0; i < length / n; i++) {
        arr.push(str.slice(n * i, n * (i + 1)));
      }
      return arr.join('<br />');
    });
    // ownerstr

    const provperOwners = computed(() => {
      const str = props.dataObj?.ownerstr;
      const length = props.dataObj?.ownerstr?.length || 1;
      const n = 20;
      const arr = [];
      for (let i = 0; i < length / n; i++) {
        arr.push(str.slice(n * i, n * (i + 1)));
      }
      return arr.join('<br />');
    });

    return {
      changePic,
      selectPic,
      changeSelectPic,
      src,
      levelArr,
      deleteProject,
      jump2detail,
      provpers,
      provperOwners,
    };
  },
});
