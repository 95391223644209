<template>
  <el-dialog
    custom-class="create-form-dialog"
    v-model="visible"
    title="新建项目"
    width="520px"
    @close="closeDialog"
    destroy-on-close
  >
    <el-steps :active="step" simple style="margin-bottom: 20px">
      <el-step title="基本设置"></el-step>
      <el-step title="高级设置"></el-step>
    </el-steps>
    <base-form
      :visible="visible"
      :base-form="baseForm"
      v-show="step === 1"
      @complete="handleComplete"
      @cancel="handleCancel"
    />
    <data-config
      v-if="visible"
      :visible="visible"
      :base-form="baseForm"
      v-show="step === 2"
      @complete="handleComplete"
      @cancel="handleCancel"
      @change-step="handleChangeStep"
    />
  </el-dialog>
</template>
<script>
import { defineComponent, reactive, ref } from 'vue';
import BaseForm from './Base.vue';
import DataConfig from './DataConfig.vue';
export default defineComponent({
  name: 'CreateFormDialog',
  components: {
    BaseForm,
    DataConfig,
  },
  setup() {
    const visible = ref(false);
    const step = ref(1);
    const baseForm = reactive({
      base: {
        name: '',
        description: '',
        templateId: '',
        license: 1,
        level: 3,
        status: 1,
        remark: '',
      },
      dbConfig: {
        source: 0,
        url: '',
        username: '',
        password: '',
        authType: 0,
      },
    });
    const openDialog = () => {
      visible.value = true;
    };

    const closeDialog = () => {
      visible.value = false;
      step.value = 1;
    };

    const handleComplete = ({ nextStep, currentStep, form }) => {
      step.value = nextStep;
      if (currentStep === 1) {
        baseForm.base = form;
      } else {
        baseForm.dbConfig = form;
      }
    };

    const handleCancel = () => {
      closeDialog();
    };

    const handleChangeStep = (changedStep) => {
      step.value = changedStep;
    };

    return {
      visible,
      step,
      baseForm,
      openDialog,
      closeDialog,
      handleComplete,
      handleCancel,
      handleChangeStep,
    };
  },
});
</script>
<style lang="scss">
.create-form-dialog {
  .form-btns {
    margin: 40px 0 0 0;
    text-align: center;
  }
}
</style>
