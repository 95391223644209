<template>
  <el-form :model="form" ref="fromRef">
    <el-form-item
      label="项目英文名"
      :label-width="labelWidth"
      prop="name"
      :rules="[
        { required: true, message: '请输入英文名称', trigger: 'blur' },
        { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' },
        {
          validator: checkProjectName,
          trigger: 'blur',
        },
      ]"
    >
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item
      label="项目中文名"
      :label-width="labelWidth"
      prop="description"
      :rules="[
        { required: true, message: '请输入项目中文名称', trigger: 'blur' },
        { min: 3, max: 20, message: '请输入3到20个字符', trigger: 'blur' },
      ]"
    >
      <el-input v-model="form.description" ref="projectDescriptionInput"></el-input>
    </el-form-item>
    <el-form-item
      label="代码模板"
      prop="templateId"
      :label-width="labelWidth"
      :rules="[{ required: true, message: '请选择代码模板', trigger: 'change' }]"
    >
      <el-select v-model="form.templateId" placeholder="请选择代码模板" default-first-option style="width: 100%">
        <el-option
          v-for="(item, index) in codeTemplateList"
          :key="index"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="项目级别"
      prop="level"
      :label-width="labelWidth"
      :rules="[{ required: true, message: '请选择项目级别', trigger: 'blur' }]"
    >
      <el-radio v-model="form.level" :label="1">通用级</el-radio>
      <el-radio v-model="form.level" :label="2">行业级</el-radio>
      <el-radio v-model="form.level" :label="3">租户级</el-radio>
    </el-form-item>
    <el-form-item
      label="许可类型"
      prop="license"
      :label-width="labelWidth"
      :rules="[{ required: true, message: '请选择许可类型', trigger: 'blur' }]"
    >
      <el-radio v-model="form.license" :label="0">永久</el-radio>
      <el-radio v-model="form.license" :label="1">租用</el-radio>
    </el-form-item>
    <el-form-item
      label="项目状态"
      prop="status"
      :label-width="labelWidth"
      :rules="[{ required: true, message: '请选择项目状态', trigger: 'blur' }]"
    >
      <el-radio v-model="form.status" :label="1">启用</el-radio>
      <el-radio v-model="form.status" :label="0">冻结</el-radio>
    </el-form-item>
    <el-form-item
      label="项目描述"
      :label-width="labelWidth"
      prop="remark"
      :rules="[{ min: 0, max: 512, message: '最多支持512个字符', trigger: 'blur' }]"
    >
      <el-input v-model="form.remark" type="textarea" :rows="5"></el-input>
    </el-form-item>
    <el-form-item class="form-btns">
      <el-button type="primary" @click="onSubmit">下一步</el-button>
      <el-button @click="handleCancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { defineComponent, reactive, ref, watch } from 'vue';
import { projectNameTest } from '@/api/project/project';
import { codeTemplateList } from '@/views/project-management/utils/project-data-utils';

export default defineComponent({
  components: {},
  props: {
    baseForm: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: { type: Boolean },
  },
  setup(props, { emit }) {
    const form = reactive({ ...props.baseForm.base });
    const fromRef = ref(null);
    watch(
      () => props.visible,
      (val) => {
        if (!val) {
          fromRef.value.resetFields();
        }
      },
    );
    const checkEnglishName = () => {
      if (!form.name) {
        return;
      }
      projectNameTest({ name: form.name });
    };

    const checkProjectName = (rule, value, callback) => {
      const reg = /^[a-z][a-z0-9-]+[a-z0-9]$/;
      if (!reg.test(value)) {
        callback(new Error('输入小写字母/数字/中横线，字母开头，字母/数字结尾'));
      } else if (['prod', 'product', 'production', 'test'].includes(value)) {
        callback(new Error(`${value}为系统保留词，请使用其他名称`));
      } else {
        projectNameTest({ name: form.name })
          .then(({ data }) => {
            if (data.usable) {
              callback();
            }
          })
          .catch(() => {
            callback(new Error('项目名称已被使用'));
          });
      }
    };

    const setOwner = (res) => {
      form.owner = res;
    };

    const onSubmit = () => {
      fromRef.value.validate((valid) => {
        if (!valid) {
          return;
        }
        emit('complete', {
          currentStep: 1,
          nextStep: 2,
          form,
        });
      });
    };
    const handleCancel = () => {
      emit('cancel', {
        currentStep: 1,
      });
    };
    return {
      form,
      fromRef,
      labelWidth: '90px',
      checkEnglishName,
      codeTemplateList,
      checkProjectName,
      setOwner,
      onSubmit,
      handleCancel,
    };
  },
});
</script>
