
import { defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import ProjectItem from '@/views/project-management/components/ProjectItem.vue';
import PackagedPagination from '@/components/pagination/Index.vue';
import { getShowBool } from '@/utils/permission-show-module';
import {
  projectDetail,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addProjectData,
  getAllTems,
  codeTemplateList,
  deleteProject,
} from '@/views/project-management/utils/project-data-utils';
import { projectNameTest } from '@/api/project/project';
import { userProjectList, userInfo } from '@/layout/messageCenter/user-info';
import { ElMessage } from 'element-plus';
import ListWrap from '@/components/list-wrap/Index.vue';
import CreateFormDialog from './components/create/Index.vue';
import { useSaList } from '@/shared/hooks/list';
import { getProjectList } from '@/api/project';

export default defineComponent({
  name: 'ProjectList',
  components: {
    ListWrap,
    ProjectItem,
    PackagedPagination,
    CreateFormDialog,
  },
  data() {
    return {
      labelWidth: '90px',
    };
  },
  setup() {
    const addDialogVisible = ref(false);
    const persons = ref([] as any);
    const projectParentDiv = ref(null as any);
    const createFormDialogRef = ref(null as any);

    const projectDescriptionInput = ref(null as any);
    const projectNameInput = ref(null as any);
    const fetchListTimer = ref();
    const { loading, total, list, query, fetchList, handleSearch, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any, useLoading = true) => {
        if (useLoading) {
          loading.value = true;
        }
        clearTimeout(fetchListTimer.value);
        try {
          const {
            data: { count, rows, ownerUsers },
          } = await getProjectList({
            ...query,
          });
          if (ownerUsers) {
            const ownersMap = {} as any;
            ownerUsers.forEach((x: any) => {
              ownersMap[x.id] = x;
            });
            rows.forEach((x: any) => {
              // eslint-disable-next-line no-param-reassign
              x.ownerstr = x.owners
                .map((x: any) => ownersMap[x.userId]?.displayName)
                .filter((x: any) => x)
                .join(',');
            });
          }
          total.value = count;
          list.value = rows;
          fetchListTimer.value = setTimeout(() => {
            fetchList(false);
          }, 5000);
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );

    getAllTems();
    if (userProjectList.value.length > 0) {
      fetchList();
    }

    const submitLoading = ref(false);
    const submitProjectDetail = () => {
      projectNameInput.value.handleBlur();
      projectDescriptionInput.value.handleBlur();
      const nameLength = projectDetail.name.length;
      const descriptionLength = projectDetail.description;
      if (submitLoading.value) {
        return;
      }
      if (nameLength < 3 || nameLength > 20) {
        return false;
      }
      if (descriptionLength < 3 || descriptionLength > 20) {
        return false;
      }
      if (!projectDetail.templateId) {
        return false;
      }
      if (projectDetail.owner) {
        const ownerArr = projectDetail.owner.split(',');
        if (ownerArr.length > 10) {
          return ElMessage.warning('最多支持10个负责人');
        }
      }
      if (projectDetail.remark && projectDetail.remark.length > 512) {
        return false;
      }
      submitLoading.value = true;
      addProjectData()
        .then((res: any) => {
          if (res?.code === 0) {
            window.location.reload();
          }
          submitLoading.value = false;
        })
        .catch((e) => {
          console.log(e);
          submitLoading.value = false;
        });
    };

    const setOwner = (res: string) => {
      projectDetail.owner = res;
    };

    const handleCreate = () => {
      // eslint-disable-next-line no-unused-expressions
      createFormDialogRef.value?.openDialog();
    };

    watch(
      () => projectParentDiv,
      (nn: any) => {
        console.log(nn, 'div变化了');
      },
    );
    const paddings = ref('0px');
    const getPaddings = () => {
      try {
        const width = projectParentDiv.value.clientWidth - 20;
        paddings.value = `${Math.abs((width % 290) / 2) - 10}px`;
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      nextTick(() => {
        getPaddings();
      });
      window.onresize = () => {
        getPaddings();
      };
    });
    const checkEnglishName = () => {
      if (!projectDetail.name) {
        return;
      }
      projectNameTest({ name: projectDetail.name });
    };

    const validatorPass = (rule: any, value: any, callback: any) => {
      const reg = /^[a-z][a-z0-9-]+[a-z0-9]$/;
      if (!reg.test(value)) {
        callback(new Error(rule.message));
      }
    };
    watch(addDialogVisible, (nn: any) => {
      if (nn) {
        projectDetail.templateId = codeTemplateList.value[0].id;
      }
    });

    onUnmounted(() => {
      clearTimeout(fetchListTimer.value);
    });

    return {
      addDialogVisible,
      projectDetail,
      codeTemplateList,
      persons,
      submitProjectDetail,
      query,
      loading,
      total,
      list,
      fetchList,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      // closeDialog,
      deleteProject,
      setOwner,
      projectParentDiv,
      paddings,
      checkEnglishName,
      userProjectList,
      userInfo,
      validatorPass,
      getShowBool,
      submitLoading,
      projectDescriptionInput,
      projectNameInput,
      createFormDialogRef,
      handleCreate,
    };
  },
});
